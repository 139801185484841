<template>
    <div>
        <section class="mt-4 mb-4">
            <div class="container pt-2 pb-2">
                <div class="row justify-content-center">
                    <div class="col-md-9">
                        <div class="separator">SEGUINOS</div>
                    </div>
                    <div class="col-md-9 text-center mt-3">
                        <i class="fab fa-instagram social-media lift"
                            onclick="redirectNewTab('https://www.instagram.com/tio_toto_impresiones/?hl=es')"></i>&nbsp;&nbsp;
                        <i class="fab fa-facebook social-media lift"
                            onclick="redirectNewTab('https://es-la.facebook.com/tiototo.impresiones/')"></i>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-items mt-4">
            <div class="container pt-3 pb-2">
                <div class="row justify-content-center">
                    <div class="col-md-12 text-center">
                        <h4 class="title-como-imprimir">CONTACTO</h4>
                    </div>
                </div>
            </div>
        </section>
        <div class="footer">
            <div class="container px-4">
                <div class="row justify-content-center ">
                    <div class="col-md-9">
                        <div class="row mt-4">
                            <div class="col-md-6 mb-4">
                                <h6 class="text-footer">
                                    <i class="fas fa-map-marker-alt"></i> Direcci&oacute;n
                                    <a style="font-weight: 400;">Pres. Jos&eacute; Evaristo Uriburu 756, C1045AAP CABA</a>
                                </h6>
                                <h6 class="mt-4 text-footer"><i class="fas fa-clock"></i> Horario de atención: <a
                                        style="font-weight: 400;">Lunes a Viernes de 09hs a 19hs</a></h6>
                                <h6 class="mt-4 text-footer"><i class="fas fa-envelope"></i> Email: <a
                                        style="font-weight: 400;">pedidosonlinetiototo@gmail.com</a></h6>
                                <h6 class="mt-4 text-footer"><i class="fab fa-whatsapp"></i> Canal exclusivo whatsapp: <a
                                        style="font-weight: 400;">+54 11 6352-0103</a></h6>
                            </div>
                            <div class="col-md-6">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.150697246881!2d-58.401036984174944!3d-34.600350664828156!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcca94f320312d%3A0x41147af88f2a9d40!2sT%C3%ADo%20Toto%20Impresiones!5e0!3m2!1ses-419!2sar!4v1652140743985!5m2!1ses-419!2sar"
                                    width="350" height="250" style="border:0;" allowfullscreen="" loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Header',
    methods: {
    },
    computed: {
        //mapea store con la vista
    },
    props: {
        step: String,
    }
}
</script>

<style lang="css" scoped></style>
