<template>
  <div>
   <Header />
   <div v-show="loading || porcentaje > 0" id="spinner" style="position: fixed; z-index: 9999; top: 0; left: 0; width: 100%; height: 100%; background: grey; opacity: 0.5;">
    <button class="spinner-btn">
                <span class="spinner"></span>
                <span class="btn__text" v-if="porcentaje > 0">({{ porcentaje }}%) Cargando</span>
                <span class="btn__text" v-else>Cargando</span>            </button>
      </div>
    <main>
        <div class="base">
            <div class="base__content">
                <router-view :key="$route.fullPath" />
            </div>
        </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import store from '@/store/index.js';

export default {
  name: 'BaseLayout',
  components: {
    Header,
    Footer
  },
  mounted() {
    store.dispatch("setSpinner", false);
  },
  computed:{
    loading(){
      return this.$store.state.loading;
    },
    porcentaje() {
          return this.$store.state.porcentaje;
      },
  },
}
</script>

<style lang="scss" scoped>

</style>