import { createStore } from "vuex";
import person from "./modules/person"
import dataOrder from "./modules/dataOrder"
import orderSend from "./modules/orderSend"
export default createStore({
  state: {
    loading:false,
    porcentaje:0,
    order:[],
  },
  getters: {
  },
  mutations: {
    SET_SPINNER(state, payload){
      state.loading=payload;
    },
    SET_ADD_ORDER(state, payload){
      state.loading.push(payload);
    },
    SET_ADD_PORCENTAJE(state, payload){
      state.porcentaje=payload;
    },
    setLoadingFile(state, loading) {
      state.loading = loading;
  },
    setPorcentaje(state, porcentaje) {
        state.porcentaje = porcentaje;
    },
  },

  actions: {
    setSpinner({commit}, payload){
      commit('SET_SPINNER', payload)
    },
    setSpinnerFile({commit}, payload){
      commit('SET_SPINNER_FILE', payload)
    },
    addOrder({commit}, payload){
      commit('SET_ADD_ORDER', payload)
    },
    setPorcentaje({commit}, payload){
      commit('SET_ADD_PORCENTAJE', payload)
    },
    
  },
  modules: {
    person,dataOrder,orderSend
  }
});



//Module